import React from 'react'
import SeoPage from '../components/SeoPage'

import Img from '../components/Image'
import { graphql, Link } from 'gatsby'

export default ({ data, location, ...props }) => (
    <SeoPage
        location={location}
        title="Pourquoi engager un avocat criminaliste spécialisé en alcool au volant pour une accusation?"
        description="Trouvez le MEILLEUR avocat en droit criminel pour votre accusation d’alcool au volant!">
        <p>
            <strong>
                Les infractions reliées à l’alcool au volant font partie des
                « plus populaires » au Québec.{' '}
            </strong>
        </p>
        <p>
            Cela n’en fait toutefois pas une infraction de moindre gravité pour
            autant, loin de là! Les conséquences de l’alcool au volant mènent au
            casier judiciaire, aux amendes salées et à la révocation du permis
            de conduire. La récidive peut même vous amener derrière les
            barreaux…{' '}
        </p>
        <p>
            <Img
                fluid={data.img0.childImageSharp.fluid}
                title="Avocat spécialisé alcool au volant"
                alt="Une image contenant personne, mur, debout, posant"
            />
        </p>
        <p>
            Devant de telles conséquences, il est absolument essentiel de vous
            allier d’un avocat criminaliste spécialisé, et apte à vous défendre.{' '}
        </p>
        <p>
            Voyez pourquoi vous avez intérêt à engager un criminaliste
            spécialisé en matière d’alcool au volant, et remplissez notre
            formulaire pour entrer en contact gratuitement avec un avocat dans
            votre région.
        </p>
        <p>
            <strong>
                Notre service de mise en contact est gratuit, et ne vous engage
                d’aucune façon – il n’y a que des avantages à utiliser{' '}
            </strong>
            <Link to="https://avocatsalcoolauvolant.ca/">
                <strong>Avocatsalcoolauvolant.ca</strong>
            </Link>
            <strong>! </strong>
        </p>
        <h2>
            Un avocat criminaliste est-il nécessaire pour une infraction
            d’alcool au volant?
        </h2>
        <p>
            Un verdict de culpabilité pour une infraction reliée à l’alcool au
            volant peut vous laisser avec un casier judiciaire et avec un permis
            de conduire révoqué.{' '}
        </p>
        <p>
            Et la récidive ne pardonne pas en matière d’alcool au volant, car
            elle mène à des périodes d’emprisonnement minimales, et à la
            révocation du permis de conduire pour plusieurs années.
        </p>
        <p> </p>
        <p>
            <strong>
                Il est donc évident que vous avez besoin d’un avocat en droit
                criminel SPÉCIALISÉ en matière d’alcool au volant pour vous
                représenter.{' '}
            </strong>
        </p>
        <p>
            L’alcool au volant est une infraction délicate à contrer, et pour
            laquelle l’absolution n’est pas une option. Il est donc essentiel de
            négocier l’abandon des accusations avec le procureur, ou d’obtenir
            un verdict de non-culpabilité à l’issue du procès.{' '}
        </p>
        <p>
            <strong>
                Mais pourquoi l’avocat en droit criminel est-il le professionnel
                le mieux qualifié pour vous représenter?{' '}
            </strong>
        </p>
        <p>
            L’avocat criminaliste est en réalité le <strong>seul</strong>{' '}
            professionnel qualifié et autorisé à vous représenter en matière
            d’alcool au volant. Ses nombreuses années de pratique au sein de
            l’appareil judiciaire font de lui votre meilleure chance de vous
            extirper des chefs d’accusation déposés contre vous.{' '}
        </p>
        <p>
            <strong>
                Voyez pourquoi il est dans votre intérêt de solliciter
                rapidement un avocat criminaliste spécialisé en dossiers
                d’alcool au volant.{' '}
            </strong>
        </p>
        <h2>
            Quels avantages d’engager un avocat criminaliste pour conduite avec
            facultés affaiblies?{' '}
        </h2>
        <p>
            <strong>
                Engager un avocat criminaliste en matière de facultés
                affaiblies, c’est plus qu’avantageux, c’est essentiel. Voici
                pourquoi!{' '}
            </strong>
        </p>
        <p>
            <strong>
                <Img
                    fluid={data.img1.childImageSharp.fluid}
                    title="Avantages avocat criminaliste facultés affaiblies"
                    alt="Une image contenant intérieur, arme"
                />
            </strong>
        </p>
        <h3>
            L’avocat criminaliste connaît le Code criminel et le projet de loi
            C-2 en détail{' '}
        </h3>
        <p>
            Le Code criminel est la loi qui prévoit l’infraction de conduite
            avec les facultés affaiblies, tandis que le projet de loi (adopté en
            2008) encadre les règles de preuve concernant le prélèvement
            d’échantillons d’haleine (Alcootests et autres).{' '}
        </p>
        <p>
            L’avocat criminaliste connait ces textes de loi sur le bout des
            doigts, ce qui lui permet de vous représenter adéquatement, mais
            également d’identifier rapidement vos chances de succès.{' '}
        </p>
        <h3>L’avocat en droit criminel peut négocier avec le procureur </h3>
        <p>
            L’infraction d’alcool au volant n’est pas la plus facile à négocier
            auprès des procureurs de la poursuite, surtout si un alcootest a été
            échoué et que toutes les procédures ont été respectées.{' '}
        </p>
        <p>
            Toutefois, les négociations avec le procureur font partie intégrante
            de l’expertise d’un avocat criminel. Votre juriste tentera donc
            d’obtenir l’abandon des accusations, ou encore, de négocier un
            plaidoyer de culpabilité.{' '}
        </p>
        <h3>
            Un avocat en droit criminel identifiera rapidement votre moyen de
            défense
        </h3>
        <p>
            Les moyens de défense sont limités lorsqu’il est question d’alcool
            au volant, et le temps est compté pour trouver la bonne approche.
            Fort de plusieurs années d’expérience, les avocats criminalistes en
            ont vu d’autres, et sont en mesure d’identifier sans délai la bonne
            défense à soulever.{' '}
        </p>
        <h3>
            L’avocat criminaliste connaît les procédures policières à respecter{' '}
        </h3>
        <p>
            Il est fréquent qu’une erreur de procédure ou une violation des
            droits de l’accusé justifie l’abandon des procédures en matière
            d’alcool au volant. Il s’agit d’un atout non négligeable lors de
            l’élaboration de votre défense.{' '}
        </p>
        <h2>
            Les infractions d’alcool au volant contre lesquelles un avocat peut
            vous défendre!
        </h2>
        <p>
            <strong>
                Le Code criminel prévoit plusieurs infractions différentes en
                matière d’alcool au volant, alors voici celles contre lesquelles
                un avocat peut vous défendre!{' '}
            </strong>
        </p>
        <h3>Conduites avec les facultés affaiblies par l’alcool </h3>
        <p>
            Le Code criminel prévoit qu’une personne commet une infraction
            lorsqu’elle conduit un véhicule alors que ses facultés sont
            affaiblies par l’alcool ou la drogue, et ce, quel que soit le degré.
            Une personne peut dont être reconnue coupable d’une infraction si
            les policiers jugent que ses facultés sont affaiblies, même si elle
            ne dépasse pas le taux légal.{' '}
        </p>
        <h3>Conduite avec plus de 80mg d’alcool /100ml de sang</h3>
        <p>
            Le Code criminel mentionne spécifiquement qu’un conducteur qui
            conduite avec un taux d’alcoolémie de 80mg d’alcool par 100ml de
            sang commet une infraction passible de sanctions. Ce taux
            d’alcoolémie doit être mesuré par les policiers à l’aide d’un
            appareil de détection approuvé.{' '}
        </p>
        <h3>Conduite avec les facultés affaiblies par la drogue </h3>
        <p>
            La conduite avec les facultés affaiblies peut également concerner
            l’usage de drogues comme le cannabis. Une accusation criminelle peut
            être portée à partir de 2 nanogrammes de THC par millilitre de sang.{' '}
        </p>
        <h3>Garde et contrôle d’un véhicule en état d’ébriété </h3>
        <p>
            Une accusation d’alcool au volant peut être portée contre vous-même
            si vous n’avez jamais mis le véhicule en marche. Il faudra démontrer
            que vous étiez en situation de garde et de contrôle d’un véhicule
            (ex : assis sur le siège conducteur) alors que votre taux
            d’alcoolémie dépassant la limite permise.{' '}
        </p>
        <p>
            Quelle que soit l’infraction spécifique qu’on vous reproche en
            matière d’alcool au volant, les conséquences mènent toutes vers une
            peine criminelle et un casier judiciaire. Assurez-vous de contacter
            un avocat alors qu’il est encore temps!{' '}
        </p>
        <h2>
            Quand faut-il contacter un avocat criminaliste en matière d’alcool
            au volant?{' '}
        </h2>
        <p>
            Il est rarement avantageux d’attendre avant de contacter un avocat
            lorsqu’on fait face à la justice criminelle. Et l’accusation de
            conduite avec facultés affaiblies ne fait pas exception à cette
            règle. En effet, l’avocat criminaliste est essentiel à toutes les
            étapes d’une accusation d’alcool au volant, dont celles-ci :
            <strong> </strong>
        </p>
        <p>
            <Img
                fluid={data.img2.childImageSharp.fluid}
                title="Quand contacter un avocat alcool au volant"
                alt="Une image contenant voiture, personne, homme, sport"
            />
        </p>
        <h3>Dès le moment de l’arrestation </h3>
        <p>
            <em>
                Le droit à un avocat s’applique dès le moment de votre
                arrestation pour alcool au volant. Assurez-vous de faire valoir
                ce droit, et de contacter un professionnel du droit sans délai.{' '}
            </em>
        </p>
        <h3>Au moment de négocier avec le procureur </h3>
        <p>
            <em>
                Une fois les chefs d’accusation déposés contre vous
                officiellement, l’avocat criminaliste intervient pour négocier
                avec le procureur aux poursuites criminelles et pénales. Rien
                n’oblige ce dernier à négocier, mais c’est le rôle de votre
                avocat de tenter le coup.{' '}
            </em>
        </p>
        <h3>Pour vous représenter lors du procès </h3>
        <p>
            <em>
                Advenant que votre accusation soit portée jusque devant un juge,
                votre avocat criminaliste sera à vos côtés pour faire valoir une
                défense solide, pleine et entière. À cette étape, les services
                d’un avocat en droit criminel sont indispensables.{' '}
            </em>
        </p>
        <h2>Les conséquences qu’un avocat criminaliste peut vous éviter!</h2>
        <p>
            N’oubliez pas que l’intérêt d’engager un avocat criminaliste est
            d’abord d’éviter les pires conséquences d’une condamnation pour
            alcool au volant.{' '}
        </p>
        <p>
            <strong>
                Qu’arrive-t-il si on vous reconnaît coupable d’une infraction
                d’alcool au volant? Voici les conséquences qu’un avocat
                criminaliste peut vous aider à éviter!{' '}
            </strong>
        </p>
        <table>
            <tr>
                <td>
                    <p>Révocation du permis de conduire </p>
                </td>
                <td>
                    <p>1 an pour une première infraction* </p>
                    <p>
                        <em>*Possibilité d’antidémarreur éthylométrique</em>
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>L’amende minimale </p>
                </td>
                <td>
                    <p>1000$ d’amende pour une première infraction </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Casier judiciaire </p>
                </td>
                <td>
                    <p>
                        Une personne reconnue coupable d’alcool au volant se
                        fait imposer un casier judiciaire automatiquement.{' '}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>L’emprisonnement </p>
                </td>
                <td>
                    <p>
                        Un emprisonnement minimal de 30 jours s’applique pour
                        une 2<sup>ième</sup> infraction.
                    </p>
                    <p>
                        Un emprisonnement de 120 jours est imposé pour un 3
                        <sup>ième</sup> infraction.{' '}
                    </p>
                </td>
            </tr>
            <tr>
                <td>
                    <p>Les frais </p>
                    <p>
                        (Récupérer permis de conduire, antidémarreur,
                        remorquage, etc.){' '}
                    </p>
                </td>
                <td>
                    <p>
                        Le total des frais à acquitter en cas de verdict de
                        culpabilité monte à plusieurs milliers de dollars.{' '}
                    </p>
                </td>
            </tr>
        </table>
        <p>
            <strong>
                Des conséquences vous seront imposées DÈS le moment de votre
                arrestation!{' '}
            </strong>
        </p>
        <p>
            Avant même que vous ne soyez reconnu coupable par un juge, votre
            permis de conduire sera suspendu pour 90 jours, et votre véhicule
            pourrait même être saisi si votre taux d’alcoolémie dépassait le
            double de la limite permise (ou en cas de récidive).{' '}
        </p>
        <p>
            <strong>
                Engager un avocat criminaliste permet donc de maximiser vos
                chances d’obtenir un verdict de non-culpabilité, évitant ainsi
                ces conséquences néfastes!{' '}
            </strong>
        </p>
        <h2>
            Comment un avocat en droit criminel peut-il vous défendre? Défenses
            d’alcool au volant!{' '}
        </h2>
        <p>
            Les avocats criminalistes sont en mesure de soulever plusieurs
            défenses contre une accusation reliée à l’alcool au volant au
            Québec!<strong> </strong>
        </p>
        <p>
            <strong>
                <Img
                    fluid={data.img3.childImageSharp.fluid}
                    title="Comment se défendre avec un avocat criminaliste?"
                    alt="Une image contenant homme, personne"
                />
            </strong>
        </p>
        <p>
            <strong>
                Voici quelques-unes des défenses qui pourraient être soulevées
                pour vous sortir d’impasse en matière de conduite avec facultés
                affaiblies :{' '}
            </strong>
        </p>
        <ul>
            <li>
                <em>L’interception illégale du conducteur </em>
            </li>
            <li>
                <em>Le mauvais fonctionnement de l’alcootest </em>
            </li>
            <li>
                <em>
                    La mauvaise utilisation de l’alcootest (par les policiers){' '}
                </em>
            </li>
            <li>
                <em>Délais dans l’échantillonnage d’haleine </em>
            </li>
            <li>
                <em>
                    Non-respect des droits fondamentaux de l’accusé (Ex :
                    silence, avocat, etc).
                </em>
            </li>
        </ul>
        <p>
            <strong>Tournez-vous vers </strong>
            <Link to="https://avocatsalcoolauvolant.ca/">
                <strong>Avocatsalcoolauvolant.ca</strong>
            </Link>
            <strong>
                {' '}
                pour trouver sans délai votre avocat criminaliste. Nos services
                de mise en contact sont gratuits et extrêmement efficaces.{' '}
            </strong>
        </p>
        <h2>
            Avocatsalcoolauvolant.ca est LA référence pour trouver le bon
            avocat!
        </h2>
        <p>
            <strong>
                Ne commettez pas l’erreur d’affronter une accusation d’alcool au
                volant sans l’aide d’un avocat criminaliste.{' '}
            </strong>
        </p>
        <p>
            Les professionnels du droit criminel sont de véritables spécialistes
            en matière de conduite avec les facultés affaiblies et d’alcool au
            volant. Ils ne demandent qu’à vous aider, alors qu’attendez-vous
            pour faire confiance à un tel professionnel?{' '}
        </p>
        <p>
            <strong>
                Remplissez notre formulaire au bas de la page afin d’entrer en
                contact gratuitement avec un avocat pour votre accusation
                d’alcool au volant.
            </strong>
        </p>
        <p>
            <strong>
                Notre service est gratuit et ne vous engage d’aucune façon!{' '}
            </strong>
        </p>
    </SeoPage>
)

export const pageQuery = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        img0: file(
            relativePath: {
                eq: "avocat-criminel-alcool-volant/avocat-criminaliste-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img1: file(
            relativePath: {
                eq: "avocat-criminel-alcool-volant/avocat-criminel-alcool-conduite.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img2: file(
            relativePath: {
                eq: "avocat-criminel-alcool-volant/contacter-avocat-alcool-volant.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        img3: file(
            relativePath: {
                eq: "avocat-criminel-alcool-volant/defense-conduite-facultes-affaiblies.jpeg"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
